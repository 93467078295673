import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const OrderDetails = ({ ToggleOpen, open, data }) => {
  return (
    <>
      <Dialog
        open={open}
        keepMounted
        onClose={() => ToggleOpen()}
        aria-describedby="alert-dialog-slide-description"
        maxWidth={"md"}
        fullWidth={true}
      >
        <DialogTitle>{"Order Details"} </DialogTitle>

        <DialogContent>
          {data && data.length > 0 ? (
            data.map((event, index) => (
              <div key={index} className="shadow p-1 mb-1 bg-body">
                <div className="d-flex flex-wrap">
                  {open && (
                    <img
                      src={event.productPic} // Assuming params.value contains the image URL
                      alt="Product Image"
                      style={{ width: 50, height: 50 }}
                    />
                  )}
                  &nbsp;
                  {"- "}
                  <span className="btn btn-sm">{event.current_quantity}</span>
                  {"- "}
                  &nbsp;
                  <span className="btn btn-sm">{event.name}</span>
                  {/* <p>Store: {event.store_id}</p> */}
                </div>
              </div>
            ))
          ) : (
            <p>No order details available</p>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => ToggleOpen()}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OrderDetails;
