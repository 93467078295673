import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CircularProgress from "@mui/material/CircularProgress";

import Select from "react-select";
// const api = axios.create({
//   baseURL: process.env.REACT_APP_API,
// });
import api from "../hooks/apiClient";

const Products = ({ authToken }) => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(true);
  const [storesData, setStoresData] = useState([]);
  const [dStoresData, setDStoresData] = useState([]);
  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  const [activeButton, setActiveButton] = useState(null);
  const [allButton, setAllButton] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedClientStore, setSelectedClientStore] = useState(null);

  const handleChange = (selectedOption) => {
    setSelectedClientStore(selectedOption);
  };

  const getProducts = (storeId, status) => {
    if (status == "all") {
      setAllButton(true);
    } else {
      setAllButton(false);
    }
    setLoading(true);
    setActiveButton(storeId);
    const params = new URLSearchParams({
      param: status,
    }).toString();

    api
      .get(`products/bystore/${storeId}?${params}`, {
        headers: {
          token: authToken,
        },
      })
      .then((response) => {
        const productsWithTotalQuantity = response.data.map((product) => ({
          ...product,
          totalQuantity: product.variants.reduce(
            (acc, variant) => acc + variant.inventory_quantity,
            0
          ),
        }));
        setData(productsWithTotalQuantity);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    const Stores = async () => {
      try {
        const response = await api.get(
          "stores/active",

          {
            headers: {
              token: authToken,
            },
          }
        );

        setStoresData(response.data.source);
        const mkobj = makevalue(response.data.destination);
        setDStoresData(mkobj);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error making request:", error);
      }
    };
    Stores();
  }, []);
  function makevalue(array) {
    return array.map((obj) => ({
      value: obj.id,
      label: obj.public_name,
      ...Object.fromEntries(
        Object.entries(obj).filter(
          ([key]) => key !== "id" && key !== "public_name"
        )
      ),
    }));
  }
  const MapTheseProducts = async () => {
    setLoading(true);
    if (selectedRows.length == 0 || !selectedClientStore) {
      setSnackbar({
        children: "Products And Store cannot be empty..",
        severity: "warning",
      });
      setLoading(false);
      return null;
    }

    try {
      const response = await api.post(`products/multi/map`, {
        client_store: selectedClientStore.value,
        product_ids: selectedRows,
      });
      console.log(response.data.data);
      setSnackbar({ children: response.data.message, severity: "success" });
    } catch (error) {
      setSnackbar({
        children: "some thing went wrong",
        severity: "error",
      });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const columns = [
    {
      field: "image_src", // You can use a field that represents the image URL
      headerName: "Image",
      width: 70,
      renderCell: (params) => (
        <img
          src={params.value} // Assuming params.value contains the image URL
          alt="Product Image"
          style={{ width: 50, height: 50 }}
        />
      ),
    },
    { field: "title", headerName: "Title", width: 500 },
    { field: "id", headerName: "Product ID", width: 150 },
    { field: "status", headerName: "Status", width: 100 },
    { field: "totalQuantity", headerName: "Inventory", width: 150 },
    {
      field: "mapped",
      headerName: "Mapped",
      width: 250,
      renderCell: (params) => (
        <>
          {params.row.mapped &&
            params.row.mapped.split(",").map((value, index) => (
              <span
                key={index}
                type="button"
                className="btn btn-sm btn-warning me-1 mb-1"
                onClick={() =>
                  console.log(`Button clicked for value: ${value}`)
                }
              >
                {value.trim()}{" "}
                {/* Use .trim() to remove leading/trailing spaces */}
              </span>
            ))}
        </>
      ),
    },
  ];
  const SyncOneProduct = async () => {
    const userConfirmed = window.confirm(
      "Are you sure you want to sync your product"
    );
    if (userConfirmed) {
      try {
        const response = await api.get(`products/shopify/`, {
          headers: {
            token: authToken,
          },
        });

        setSnackbar({ children: response.data.message, severity: "success" });
      } catch (error) {
        console.error("Error making request:", error);
        setSnackbar({ children: error.message, severity: "error" });
      }
    } else {
      setSnackbar({ children: "Void the Operation", severity: "error" });
    }
  };
  const handleRowClick = (params) => {
    const { row } = params;
    const { id } = row;

    navigate(`/productdetails/${id}`);
  };

  if (loading) {
    return (
      <div>
        {" "}
        <CircularProgress />
      </div>
    );
  }
  const handleTabs = (event, newValue) => {
    getProducts(newValue, "active");

    setValue(newValue);
  };
  return (
    <div className="mt-2">
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}

      <div className="d-flex flex-column justify-content-center shadow p-2 mb-3 bg-body rounded">
        <h6>Select A store </h6>

        <div className=" d-flex flex_wrap justify-content-center">
          <div className="d-flex justify-content-end">
            <Tabs value={value} onChange={handleTabs} centered>
              {storesData &&
                storesData.map((store) => (
                  <Tab
                    label={store.public_name}
                    value={store.id}
                    key={store.id}
                  />
                ))}
              <Tab label="noirs" value={577} />
            </Tabs>
          </div>
        </div>
        <div className=" d-flex flex_wrap justify-content-center">
          {activeButton && data && data.length > 0 && (
            <>
              <button
                className={`btn btn-sm btn-outline-info m-1 ${
                  allButton ? "active" : ""
                }`}
                onClick={() => getProducts(activeButton, "all")}
              >
                All
              </button>
              <div className="floating-button d-flex justify-content-evenly shadow p-2 mb-3 bg-body rounded ">
                <div className="">
                  <Select
                    isLoading={loading}
                    value={selectedClientStore}
                    onChange={handleChange}
                    options={dStoresData}
                    isSearchable={true}
                    placeholder="Store To Map"
                  />
                </div>
                <div>
                  {selectedClientStore && (
                    <button
                      className="btn btn-outline-secondary ms-1"
                      onClick={() => MapTheseProducts()}
                    >
                      Map these
                    </button>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {data && data.length > 0 && (
        <div style={{ height: 1000, width: "100%" }}>
          <DataGrid
            rows={data}
            columns={columns}
            pageSize={5}
            getRowId={(row) => row.id}
            onRowClick={handleRowClick}
            checkboxSelection
            onRowSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedRows = data.filter((row) =>
                selectedIDs.has(row.id)
              );
              setSelectedRows(selectedRows);
            }}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Products;
