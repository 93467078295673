import React from "react";

const SelectCourier = ({ couriers, value, onChange }) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <select
      className="form-control form-control-sm"
      value={value}
      onChange={handleChange}
    >
      <option value="">Select a Courier</option>
      {couriers.map((courier) => (
        <option key={courier.id} value={courier.id}>
          {courier.name}
        </option>
      ))}
    </select>
  );
};

export default SelectCourier;
