import React, { useState } from "react";
import axios from "axios";

const ImageUploader = ({ setOpen, userId, authToken }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [msg, setMsg] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleImageChange = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  const handleImageUpload = async () => {
    if (!selectedImage) {
      return;
    }

    setUploading(true);
    const formData = new FormData();
    formData.append("image", selectedImage);

    try {
      const response = await axios.post(
        `http://localhost:4400/api/v1/upload/img/${userId}`,
        formData,
        {
          headers: {
            token: authToken,
          },
        },
        {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            setUploadProgress(progress);
          },
        }
      );
      setMsg(response.data);
      setSelectedImage(null);
      setTimeout(() => {
        setOpen();
      }, 1000);
    } catch (error) {
      console.error("Error uploading image:", error);
      // Handle error, e.g., show an error message to the user
    } finally {
      setUploading(false);
    }
  };

  return (
    <div>
      <p>{userId}</p>
      <input
        className="mt-2 form-control"
        type="file"
        onChange={handleImageChange}
      />
      <button
        className="mt-2 btn btn-sm"
        onClick={handleImageUpload}
        disabled={!selectedImage || uploading}
      >
        {uploading ? "Uploading..." : "Upload Image"}
      </button>
      {msg && <p>{msg}</p>}
      {selectedImage && (
        <img
          src={URL.createObjectURL(selectedImage)}
          alt="Selected Image"
          style={{ width: "100px", height: "auto" }}
        />
      )}
      {uploading && <progress value={uploadProgress} max="100" />}
    </div>
  );
};

export default ImageUploader;
