import React, { useEffect, useState } from "react";

import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { format } from "date-fns";

import api from "../hooks/apiClient";
const PaidOrders = ({ show }) => {
  const [data, setData] = useState([]);
  const [storesData, setStoresData] = useState([]);
  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  const [loading, setLoading] = useState(true);
  const [btnID, setBtnID] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 50,
    page: 0,
  });
  const [rowCount, setRowCount] = useState(0);

  useEffect(() => {
    api
      .get("stores/all")
      .then((response) => {
        setStoresData(response.data);

        getOrdersOfStore(response.data[0].id, paginationModel);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);
  async function getOrdersOfStore(store, pagination) {
    setBtnID(store);
    setLoading(true);
    try {
      const response = await api.get(`orders/paid/bystore/${store}`, {
        params: {
          page: pagination.page,
          pageSize: pagination.pageSize,
        },
      });
      setData(response.data.orders);
      setRowCount(response.data.totalCount);
      if (response.data.orders.length === 0) {
        setSnackbar({
          children: "Store does not have any orders ...",
          severity: "warning",
        });
      }
    } catch (error) {
      setData([]);
      setSnackbar({
        children: "Store does not have any orders. See the error log.",
        severity: "warning",
      });

      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }

  const columns = [
    { field: "store_id", headerName: "SID", width: 100 },
    { field: "name", headerName: "Order", width: 150 },
    {
      field: "financial_status",
      headerName: "Status",
      width: 150,
    },

    {
      field: "isPaid",
      headerName: "Is paid?",
      type: "boolean",
      width: 140,
      editable: true,
    },

    {
      field: "isDelivered",
      headerName: "Is Delivered?",
      type: "boolean",
      width: 140,
      editable: true,
    },
    {
      field: "createdAt",
      headerName: "C Date",
      width: 150,
      renderCell: (params) => {
        const date = new Date(params.value);
        return format(date, "dd/MM/yyyy hh:mm a");
      },
    },
    {
      field: "updatedAt",
      headerName: "UpDate",
      width: 150,
      renderCell: (params) => {
        const date = new Date(params.value);
        return format(date, "dd/MM/yyyy hh:mm a");
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Action",
      width: 100,
      getActions: (params) => {
        const actions = [];

        actions.push(
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => deleteOrder(params.row.id)}
          />
        );
        return actions;
      },
    },
  ];
  const deleteOrder = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this Product?"
    );
    try {
      if (confirmed) {
        const response = await api.delete(`orders/${id}`, {});

        setSnackbar({
          children: response.data,
          severity: "success",
        });
      } else {
        setSnackbar({
          children: "You Did not confirm to delet Not Deleted ",
          severity: "warning",
        });
      }
    } catch (error) {
      console.error("Error delet order data:", error);

      setSnackbar({
        children: error.message,
        severity: "error",
      });
    }
  };

  async function handlePageChange(pagination) {
    setPaginationModel(pagination);

    if (btnID) {
      await getOrdersOfStore(btnID, pagination); // Re-fetch orders on pagination change
    }
  }

  return (
    <div>
      <button
        className="btn btn-sm btn-outline-secondary me-5 float-end"
        onClick={show}
      >
        Show Progress
      </button>
      <Snackbar
        open={snackbar ? true : false}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleCloseSnackbar}
        autoHideDuration={6000}
      >
        <Alert {...snackbar} onClose={handleCloseSnackbar} />
      </Snackbar>
      <div className="container mt-2">
        <div className="shadow bg-body p-1 rounded">
          {storesData.map((store) => (
            <button
              key={store.id}
              className={`btn btn-sm btn-outline-info m-1 ${
                btnID === store.id ? "active" : ""
              }`}
              onClick={() => getOrdersOfStore(store.id, paginationModel)}
            >
              {store.public_name}
            </button>
          ))}
        </div>
      </div>
      {data.length > 0 ? (
        <div className="container mt-2">
          <div style={{ height: 1000, width: "100%" }}>
            <DataGrid
              paginationModel={paginationModel}
              onPaginationModelChange={handlePageChange}
              paginationMode="server"
              loading={loading}
              rowCount={rowCount}
              rows={data}
              columns={columns}
              rowHeight={28}
              getRowId={(row) => row.id}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
            />
          </div>
        </div>
      ) : (
        <p>Please select a store</p>
      )}
    </div>
  );
};

export default PaidOrders;
