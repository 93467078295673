import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Snackbar, Button } from "@mui/material";
import Alert from "@mui/material/Alert";
import OrderDetail from "../components/OrderDetails";
import OrdersTable from "../components/OrdersTable";
import CircularProgress from "@mui/material/CircularProgress";

import api from "../hooks/apiClient";
const Orders = ({ authToken }) => {
  const navigate = useNavigate();
  const [storesData, setStoresData] = useState([]);
  const [dStoresData, setDStoresData] = useState([]);
  const [activeButton, setActiveButton] = useState(null);
  const [order, setOrder] = useState();
  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  const ToggleOpen = () => {
    setOpen(!open);
  };
  useEffect(() => {
    const Stores = async () => {
      try {
        const response = await api.get("stores/active");
        setStoresData(response.data.source || []);
        setDStoresData(response.data.destination || []);

        if (response.data.source?.length > 0) {
          setActiveButton(response.data.source[0].id); // Set first store as default
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error making request:", error);
      }
    };
    Stores();
  }, []);

  const ViewOrder = async (id) => {
    try {
      const response = await api.get(`orders/finddetails/${id}`);

      setOrder(response.data);

      ToggleOpen();
    } catch (error) {
      console.error(error);
    }
  };
  const ViewOrderDetails = async (id) => {
    navigate(`/orders/${id}`);
  };
  const OrdersForBooking = async (store_id) => {
    navigate(`/orders/booking/${store_id}`);
  };

  if (loading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <Snackbar
        open={!!snackbar} // Use !! to convert to boolean
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleCloseSnackbar}
        autoHideDuration={6000}
      >
        {snackbar && <Alert {...snackbar} onClose={handleCloseSnackbar} />}
      </Snackbar>

      <div className="d-flex flex-column justify-content-center shadow-sm p-2 mb-3 bg-body rounded">
        <div className="shadow p-1 bg-body rounded floating-button-top">
          <button
            className="btn btn-sm btn-outline-secondary "
            onClick={() => OrdersForBooking(activeButton)}
            disabled={!activeButton}
          >
            Go For Booking
          </button>
        </div>
        <h6>Select A store </h6>

        <div className=" d-flex flex_wrap justify-content-center">
          <div className="d-flex justify-content-end shadow-sm p-2 ms-3 bg-body rounded">
            {storesData &&
              storesData.map((store) => (
                <button
                  key={store.id}
                  className={`btn btn-sm btn-outline-info m-1 ${
                    activeButton === store.id ? "active" : ""
                  }`}
                  onClick={() => setActiveButton(store.id)}
                >
                  {store.public_name}
                </button>
              ))}
          </div>

          <div className="d-flex justify-content-end shadow-sm p-2 ms-3 bg-body rounded">
            <span className="btn btn-sm me-2">Destination Stores</span>
            {dStoresData &&
              dStoresData.map((store) => (
                <button
                  key={store.id}
                  className={`btn btn-sm btn-outline-info m-1 ${
                    activeButton === store.id ? "active" : ""
                  }`}
                  onClick={() => setActiveButton(store.id)}
                >
                  {store.public_name}
                </button>
              ))}
          </div>
        </div>
      </div>
      <OrderDetail open={open} ToggleOpen={ToggleOpen} data={order} />

      <>
        <OrdersTable
          setSnackbar={setSnackbar}
          ViewOrder={ViewOrder}
          ViewOrderDetails={ViewOrderDetails}
          store_id={activeButton}
        />
      </>
    </div>
  );
};

export default Orders;
