import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid"; // Updated import
// const api = axios.create({
//   baseURL: process.env.REACT_APP_API,
// });
import api from "../hooks/apiClient";
const OrderDetail = () => {
  const { id } = useParams(); // Get the product_id from URL parameter
  const [ordertData, setOrderData] = useState([]);

  const [loading, setLoading] = useState(true);
  async function getOrder() {
    try {
      const response = await api.get(`orders/finddetails/${id}`, {
        // headers: { token: authToken },
      });
      console.log(response);
      setOrderData(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }
  useEffect(() => {
    getOrder();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container shadow p-3 mb-2 d-flex justify-content-center">
      <div className="d-flex flex-column w-50">
        <div>Order: {id}</div>
        {ordertData && ordertData.length > 0 ? (
          <>
            {ordertData
              .filter(
                (event) => event.fulfillable_quantity === 0 && event.isFulfilled
              )
              .map((event, index) => (
                <div key={index} className="shadow p-1 mb-1 bg-body">
                  <div className="d-flex flex-wrap">
                    <img
                      src={event.productPic}
                      alt="Product Image"
                      style={{ width: 50, height: 50 }}
                    />
                    &nbsp;
                    <span className="btn btn-sm">{event.quantity}</span>
                    {" - "}
                    <span className="btn btn-sm">
                      {event.fulfillable_quantity}
                    </span>
                    {" - "}
                    <span className="btn btn-sm">{event.current_quantity}</span>
                    &nbsp;
                    <span className="btn btn-sm">{event.name}</span>
                  </div>
                </div>
              ))}
            {ordertData
              .filter((event) => event.fulfillable_quantity > 0)
              .map((event, index) => (
                <div key={index} className="shadow p-1 mb-1 bg-body">
                  <div className="d-flex flex-wrap">
                    <img
                      src={event.productPic}
                      alt="Product Image"
                      style={{ width: 50, height: 50 }}
                    />
                    &nbsp;
                    <span className="btn btn-sm">{event.quantity}</span>
                    {" - "}
                    <span className="btn btn-sm">
                      {event.fulfillable_quantity}
                    </span>
                    {" - "}
                    <span className="btn btn-sm">{event.current_quantity}</span>
                    &nbsp;
                    <span className="btn btn-sm">{event.name}</span>
                  </div>
                </div>
              ))}

            {ordertData
              .filter(
                (event) =>
                  event.fulfillable_quantity === 0 && !event.isFulfilled
              )
              .map((event, index) => (
                <div key={index} className="shadow p-1 mb-1 mt-5 bg-body">
                  <span className="btn btn-sm">Removed</span>
                  <div className="d-flex flex-wrap">
                    <img
                      src={event.productPic}
                      alt="Product Image"
                      style={{ width: 50, height: 50 }}
                    />
                    &nbsp;
                    <span className="btn btn-sm">{event.quantity}</span>
                    {" - "}
                    <span className="btn btn-sm">{event.current_quantity}</span>
                    &nbsp;
                    <span className="btn btn-sm">{event.name}</span>
                  </div>
                </div>
              ))}
          </>
        ) : (
          <p>No order details available</p>
        )}
      </div>
    </div>
  );
};

export default OrderDetail;
