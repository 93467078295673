import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { CircularProgress } from "@mui/material";
const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});
const ForgotPassword = ({ clientId }) => {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleForgot = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError(null);
      const resp = await api.post("auths/forgot-password", { email, clientId });

      setSuccess(" please check you email for further instructions");
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
      setError(err.response.data);
    }
  };
  return (
    <div
      className=""
      style={{
        backgroundColor: "rgba(236, 226, 230, 0.8)", // Semi-transparent red
      }}
    >
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="col-sm-10 col-lg-4 shadow p-3 mb-5 bg-body rounded">
          <p className="fw-semibold">Forgot Password</p>

          {!success && (
            <div className="d-flex flex-column">
              <TextField
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                label="Enter Email"
                variant="outlined"
                margin="normal"
                size="small"
              />
            </div>
          )}

          <br />
          {error && <p className="alert alert-danger">{error}</p>}
          {!error &&
            success && ( // Only show success message if there's no error
              <p className="alert alert-success">{success}</p>
            )}
          {!success && (
            <div>
              {!loading ? (
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={handleForgot}
                >
                  Submit
                </button>
              ) : (
                <CircularProgress color="success" />
              )}
            </div>
          )}
          <Link to="/login" className="text-decoration-none float-end">
            Go to Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
