import React, { useState } from "react";
import { Scanner } from "@yudiel/react-qr-scanner";

const QRCodeScanner = ({ showScanner, setShowScanner, handleScan, dataQr }) => {
  const handleError = (error) => {
    console.error(error);
  };

  return (
    <div>
      {showScanner && (
        <div style={{ position: "relative", width: "fit-content" }}>
          {/* Close button */}

          <br />
          <Scanner
            onScan={handleScan}
            onDecode={(result) => console.log(result)}
            onError={handleError}
            constraints={{ facingMode: "environment" }}
          />
          <p>
            {dataQr
              ? `${dataQr[0].format}: ${dataQr[0].rawValue}`
              : "No result"}
          </p>
          <button
            onClick={() => setShowScanner(false)}
            style={{
              position: "absolute",
              top: "20px",
              right: "10px",
              background: "transparent",
              border: "none",
              fontSize: "20px",
              cursor: "pointer",
            }}
          >
            &times;
          </button>
        </div>
      )}
    </div>
  );
};

export default QRCodeScanner;
