import React, { useEffect, useState, useCallback } from "react";
import SelectCourier from "../components/SelectCourier";
import Select from "../components/Select";
import api from "../hooks/apiClient";

function CourierForm({
  formData,
  setFormData,
  handleChange,
  handleSubmit,
  isEditMode,
  viewCourier,
  stores,
}) {
  const [courierNames, setCourierNames] = useState([]);
  const [selectedCourierId, setselectedCourierId] = useState("");

  const [selectedStoreId, setSelectedStoreId] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSelectChange = (value) => {
    setSelectedStoreId(value);
    setFormData({ ...formData, store_id: value });
  };

  const handleSelectChangeC = (val) => {
    setselectedCourierId(val);

    const selectedCourier = courierNames.find((courier) => courier.id == val);
    console.log(courierNames);
    setFormData({
      ...formData,
      courierName: selectedCourier.name,
      function_value: selectedCourier.value,
      name_id: val,
    });
  };

  useEffect(() => {
    api
      .get("couriers/names")
      .then((response) => {
        setCourierNames(response.data);
      })
      .catch((error) => {
        console.error("Error fetching couriers:", error);
      });
  }, []);

  return (
    <div className="d-flex justify-content-center">
      <div className="shadow-lg p-3 mb-5 bg-body rounded col-lg-6">
        <button
          type="button"
          className="btn btn-sm btn-outline-secondary float-end"
          onClick={viewCourier}
        >
          <i className="bi bi-eye"></i> &nbsp; View Couriers
        </button>
        <br />
        <br />
        <p>Please enter all information correctly as it cannot be undone.</p>

        <h4 className="mb-5 text-center">
          {isEditMode ? "Update Courier" : "Register Courier"}
        </h4>

        <form onSubmit={handleSubmit}>
          {!isEditMode ? (
            <div>
              <Select
                stores={stores}
                value={selectedStoreId}
                onChange={handleSelectChange}
              />

              <SelectCourier
                couriers={courierNames}
                value={selectedCourierId}
                onChange={handleSelectChangeC}
                loading={loading}
              />
            </div>
          ) : (
            ""
          )}
          <div className="mb-3">
            <label htmlFor="courierApi" className="form-label">
              API Or User:
            </label>
            <input
              required
              placeholder="Api Or username..."
              autoComplete="off"
              className="form-control"
              type="text"
              name="courierApi"
              value={formData.courierApi}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="apiSecret" className="form-label">
              API Token:
            </label>
            <input
              required
              placeholder="api or user password...."
              autoComplete="off"
              className="form-control"
              type="text"
              name="apiSecret"
              value={formData.apiSecret}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="apiSecret" className="form-label">
              Location ID:
            </label>
            <input
              required
              placeholder="pickup address id"
              autoComplete="off"
              className="form-control"
              type="text"
              name="pickup_address_id"
              value={formData.pickup_address_id}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="account" className="form-label">
              Account:
            </label>
            <input
              required
              placeholder="account..."
              autoComplete="off"
              className="form-control"
              type="text"
              name="account"
              value={formData.account}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <button className="btn btn-success" type="submit">
              {isEditMode ? "Update" : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CourierForm;
