import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CourierForm from "../components/CourierForm";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import LinearProgress from "@mui/material/LinearProgress";
import Select from "../components/Select";
import Cities from "./CitiesTable";
import api from "../hooks/apiClient";

const Couriers = () => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [couriersData, setCouriersData] = useState([]);
  const [cities, setCities] = useState();
  const [stores, setStores] = useState([]);
  const [selectedStoreId, setSelectedStoreId] = useState("");
  const [courierOne, setOneCourier] = useState("");
  const [viewSet, setViewSet] = useState(true);
  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  const [formData, setFormData] = useState({
    id: "",
    store_id: "",
    courierName: "",
    courierApi: "",
    apiSecret: "",
    pickup_address_id: "",
    account: "",
  });

  const handleSelectChange = (value) => {
    setSelectedStoreId(value);

    if (value) {
      fetchStoresCouriers(value);
    } else {
      setCouriersData([]);
    }
  };

  const fetchStoresCouriers = async (store_id) => {
    setLoading(true);
    try {
      const couriersResponse = await api.get("couriers/all/bystore", {
        params: {
          store_id: store_id,
        },
      });

      setCouriersData(couriersResponse.data);

      if (couriersResponse.data.length === 0) {
        setSnackbar({
          children: "No Courier is registerd with this store...",
          severity: "success",
        });
      }
    } catch (err) {
      console.error("Error fetching stores and couriers:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    api
      .get("stores/all")
      .then((response) => {
        setStores(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching stores:", error);
      });
  }, []);

  const resetForm = () => {
    setFormData({
      id: "",
      store_id: "",
      courierName: "",
      courierApi: "",
      apiSecret: "",
      pickup_address_id: "",
      account: "",
    });
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const viewCourier = () => {
    setViewSet(!viewSet);
    setIsEditMode(false);
    resetForm();
    if (!couriersData || couriersData.length === 0) {
      setSnackbar({
        children: "There is no store to show add  a new courier",
        severity: "warning",
      });
    }
  };

  const handleEdit = async (id) => {
    setViewSet((prevViewSet) => !prevViewSet);
    setIsEditMode(true);
    const courier = couriersData.filter((courier) => courier.id === id);
    setFormData({
      id: id,
      courierName: courier[0].name,
      courierApi: courier[0].api_key,
      apiSecret: courier[0].api_password,
      pickup_address_id: courier[0].pickup_address_id,
      account: courier[0].account,
    });
  };

  const getCitiesActive = async (id) => {
    setLoading(true);
    const courier = couriersData.filter((courier) => courier.id === id);
    try {
      const city = await api.get("couriers/getcities", {
        params: {
          name: courier[0].name,
          name_id: courier[0].name_id,
          courier_id: courier[0].id,
          isActive: true,
        },
      });
      setLoading(false);
      setSnackbar({
        children: city.data,
        severity: "success",
      });
    } catch (err) {
      console.error("Error fetching active cities:", err);
    } finally {
      setLoading(false);
    }
  };
  const getCities = async (id) => {
    setLoading(true);
    const courier = couriersData.filter((courier) => courier.id === id);
    try {
      const city = await api.get("couriers/getcities", {
        params: {
          name: courier[0].name,
          name_id: courier[0].name_id,
          courier_id: courier[0].id,
          isActive: false,
        },
      });
      setLoading(false);
      setSnackbar({
        children: city.data,
        severity: "success",
      });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const fetchCities = async (id) => {
    setLoading(true);
    const courier = couriersData.filter((courier) => courier.id === id);
    setOneCourier(courier);
    try {
      const city = await api.get("couriers/cities", {
        params: {
          courier_id: courier[0].id,
        },
      });

      setCities(city.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isEditMode) {
      try {
        const response = await api.put(
          `couriers/update/${formData.id}`,
          formData
        );
        setSnackbar({
          children: response.data,
          severity: "success",
        });
        setTimeout(() => {
          resetForm();
          window.location.reload();
        }, 3000);
      } catch (error) {
        console.error("Submit Failed:", error);
        setSnackbar({ children: error.message, severity: "error" });
      }
    } else {
      try {
        const response = await api.post(`couriers/add`, formData);
        setSnackbar({
          children: response.data,
          severity: "success",
        });
        setTimeout(() => {
          resetForm();
          window.location.reload();
        }, 3000);
      } catch (error) {
        console.error("Submit Failed:", error);
        setSnackbar({ children: error.message, severity: "error" });
      }
    }
  };
  async function storeActive(id, cIsActive) {
    setLoading(true);
    try {
      const response = await api.put(`couriers/isactive/${id}`, {
        isActive: !cIsActive,
      });

      setTimeout(() => {
        setSnackbar({
          children: response.data,
          severity: "success",
        });
        fetchStoresCouriers(selectedStoreId);
      }, 1000);
    } catch (error) {
      console.error("Submit Failed:", error);
      setSnackbar({ children: error.message, severity: "error" });
      setLoading(false);
    }
  }
  if (viewSet) {
    return (
      <div className="container mt-5">
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}

        <button
          type="button"
          className="btn btn-sm btn-outline-secondary float-end"
          onClick={viewCourier}
        >
          <i className="bi bi-gear"></i> &nbsp; Add New Courier
        </button>
        <br />
        <br />
        <Grid item xs={12}>
          <Grid item xs={8}>
            <Select
              stores={stores}
              value={selectedStoreId}
              onChange={handleSelectChange}
            />

            {loading && <LinearProgress />}
            {couriersData.map((courier) => (
              <div
                key={courier.id}
                className=" shadow p-1 mb-3 bg-body rounded"
              >
                <div className=" d-flex justify-content-around">
                  <span className="magic-button magic-button-green">
                    {courier.name}
                  </span>

                  <span>ID: {courier.id}</span>

                  <span>Status: {courier.status}</span>

                  <FormControlLabel
                    label={courier.isActive ? "Active" : "Inactive"}
                    control={
                      <Switch
                        checked={courier.isActive}
                        onChange={() =>
                          storeActive(courier.id, courier.isActive)
                        }
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                  />

                  <div className="">
                    <button
                      className="ms-3 btn btn-sm btn-outline-info"
                      onClick={() => fetchCities(courier.id)}
                    >
                      <i className="bi bi-pen"></i>&nbsp;Fetch Cities
                    </button>
                    <button
                      className="ms-3 btn btn-sm btn-outline-secondary"
                      onClick={() => getCities(courier.id)}
                    >
                      <i className="bi bi-buildings"></i>&nbsp;Sync Cities
                    </button>
                    <button
                      className="ms-3 btn btn-sm btn-outline-info"
                      onClick={() => getCitiesActive(courier.id)}
                    >
                      <i className="bi bi-buildings"></i>&nbsp;Sync Cities
                      Active
                    </button>
                    <button
                      className="ms-3 btn btn-sm btn-outline-secondary"
                      onClick={() => handleEdit(courier.id)}
                    >
                      <i className="bi bi-pen"></i>&nbsp;Edit
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </Grid>
          {cities && (
            <Grid xs={12}>
              <Cities courier={courierOne} cities={cities} iloading={loading} />
            </Grid>
          )}
        </Grid>
      </div>
    );
  }

  return (
    <div className="container mt-5">
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}

      <CourierForm
        formData={formData}
        setFormData={setFormData}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        isEditMode={isEditMode}
        viewCourier={viewCourier}
        stores={stores}
      />
    </div>
  );
};

export default Couriers;
