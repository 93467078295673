import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import "../pages/model.css";
// const api = axios.create({
//   baseURL: process.env.REACT_APP_API,
// });
import api from "../hooks/apiClient";
const CourierDetail = ({ authToken }) => {
  const { id } = useParams();
  const [courierData, setCourierData] = useState();
  const [courierActive, setCourierActive] = useState("");
  const [newQuantity, setNewQuantity] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectListChanged, setSelectListChanged] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  const handelAtive = (e) => {
    setCourierActive(e.target.value);
    setSelectListChanged(!selectListChanged);
    console.log(courierActive);
  };
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await api.get(`couriers/${id}`, {
          headers: {
            token: authToken,
          },
        });

        setCourierData(response.data);
        setCourierActive(response.data.isActive);
        console.log(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching courier data:", error);
        setSnackbar({
          children: "Server error no data availble",
          severity: "error",
        });
        setLoading(false);
      }
    };

    fetchData();
  }, [id, authToken]);

  const handleSubmit = () => {
    togglePopup();

    setSnackbar({
      children: "Submit Clicked",
      severity: "success",
    });
  };
  const sendApprovalRequest = async () => {
    try {
      const response = await api.get(`stores/approval/${id}`, {
        headers: {
          token: authToken,
        },
      });

      setSnackbar({
        children: response.data,
        severity: "success",
      });
      setLoading(false);
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message;
        setSnackbar({
          children: error.response.data,
          severity: "error",
        });
      } else {
        console.error("Error fetching store data:", error);
      }
      setLoading(false);
    }
  };
  const syncproducts = async () => {
    if (window.confirm("Are You sure you want to sync")) {
      setLoading(true);
      try {
        const response = await api.get(`products/shopifysync/${id}`, {
          headers: {
            token: authToken,
          },
        });

        setSnackbar({
          children: response.data,
          severity: "success",
        });
        setLoading(false);
      } catch (error) {
        console.error("Error fetching courier data:", error);

        setLoading(false);
      }
    }
  };
  const handleSaveData = () => {
    setSnackbar({
      children: `${courierActive}-${courierData.status}`,
      severity: "success",
    });
  };
  if (loading) {
    return (
      <div className="container">
        <p>loading....</p>
      </div>
    );
  }
  return (
    <div className="container-fluid">
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
      <div className="d-flex flex-wrap justify-content-evenly">
        <div className="col-lg-5 col-xl-5 col-sm-10 shadow p-3 mb-5 bg-body rounded ">
          <div className="d-flex flex-column ">
            <div className="mt-5">
              <h6 className="">{courierData.name}</h6>
            </div>

            <div className="shadow-sm p-3 mb-5 bg-body rounded ">
              <p>courier Api: {courierData.api_key}</p>
              <p>courier Id: {courierData.id}</p>

              <p>Date: {courierData.created_at}</p>
            </div>
            <div className="shadow-sm p-3 mb-5 bg-body rounded ">
              {courierData.status === "Approved" ? (
                <div>
                  <h5>Get New And Update Products</h5>
                  <button className="btn btn-info" onClick={syncproducts}>
                    Sync All Products
                  </button>
                </div>
              ) : (
                courierData.status
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-xl-4 col-sm-10  p-3 mb-5 ">
          <div className="shadow p-3 mb-3 bg-body rounded d-flex flex-column">
            <div>
              <p className="float-start fw-bold">Status</p>
              <p className="float-end fw-lighter">
                {courierData.status}
                <i
                  onClick={() => alert("i cliked")}
                  class="bi bi-three-dots-vertical float-end"
                ></i>
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <select
                value={courierActive}
                className="form-control w-25"
                name="courierStatus"
                id=""
                onChange={handelAtive}
              >
                <option value="false">Inactive</option>
                <option value="true">Active</option>
              </select>
              {courierData.status === "Pending" ? (
                <button
                  type="button"
                  className="btn btn-sm btn-outline-secondary"
                  onClick={sendApprovalRequest}
                >
                  <i className="bi bi-gear"></i> Approval and activate
                </button>
              ) : (
                selectListChanged &&
                courierActive !== courierData.status && (
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-secondary"
                    onClick={() => {
                      handleSaveData();
                      setSelectListChanged(!selectListChanged);
                    }}
                  >
                    <i className="bi bi-gear"></i> Save Data
                  </button>
                )
              )}
            </div>
          </div>
          <div className="shadow p-3 mb-3 bg-body rounded d-flex flex-column ">
            <div className="mb-3">
              <h6 className="float-start fw-bold">Publishing</h6>
              <i
                onClick={() => alert("i cliked")}
                className="bi bi-three-dots-vertical float-end"
              ></i>
            </div>
            <div className="d-flex flex-column align-items-start ">
              <p className="fw-medium">Slaes Channels</p>
              <p>
                <i className="bi bi-flower3"></i>
                &nbsp; Velvourshop
              </p>
              <p>
                <i className="bi bi-flower3"></i>
                &nbsp; style hub
              </p>
              <p>
                <i className="bi bi-flower3"></i>
                &nbsp; websnotch
              </p>
              <p>
                <i className="bi bi-flower3"></i>
                &nbsp; vestile wear
              </p>
              <p>
                <i className="bi bi-flower3"></i>
                &nbsp; Noirs courier online and offline
              </p>
            </div>
          </div>
          <div className="shadow p-3 mb-3 bg-body rounded ">
            <div className="d-flex  flex-column">
              <div className="">
                <p className="fw-bold float-start">Insights</p>
                <p className="fw-lighter float-end">Last 90 Days</p>
              </div>
              <div>
                <p>
                  Sold 19 units to 17 customers for Rs14,335.00 in net sales.
                </p>
                <Link className="float-start">View Details</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showPopup && (
        <div className="">
          <div className="modals">
            <div className="modal-contents">
              <div className="modal-header">
                <h6 className="modal-title">Enter Quantity </h6>
                <span onClick={togglePopup} className="close-btn">
                  &times;
                </span>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <input
                    placeholder="Enter Quantity"
                    type="number"
                    className="form-control m-1"
                    name="Pin"
                    value={newQuantity}
                    onChange={(e) => setNewQuantity(e.target.value)}
                  />
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={togglePopup}
                >
                  Close
                </button>
                <button
                  onClick={handleSubmit}
                  type="button"
                  className="btn btn-success m-1"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CourierDetail;
