import React, { useEffect, useState } from "react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { format } from "date-fns";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import InstagramIcon from "@mui/icons-material/Instagram";
import ChatBubbleTwoToneIcon from "@mui/icons-material/ChatBubbleTwoTone";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import TikTokIcon from "../components/TikTokIcon";

import api from "../hooks/apiClient";
const OrdersTable = ({
  ViewOrder,
  ViewOrderDetails,
  setSnackbar,
  store_id,
}) => {
  const [value, setValue] = useState("unfulfiled");
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 50,
    page: 0,
  });
  const [rowCount, setRowCount] = useState(0);
  const [activeButton, setActiveButton] = useState();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setActiveButton(store_id);
    GetOrders(store_id, paginationModel);
  }, [store_id, value, paginationModel]);
  async function GetOrders(store_id, pagination) {
    try {
      setLoading(true);
      setActiveButton(store_id);

      const response = await api.get(`orders/bystore/${store_id}`, {
        params: {
          page: pagination.page,
          pageSize: pagination.pageSize,
          orderType: value,
        },
      });

      setRowCount(response.data.totalCount); // Assuming the response contains total count
      setData(response.data.orders);
      if (response.data.orders.length === 0) {
        setSnackbar({
          children: "Store does not have any orders ...",
          severity: "warning",
        });
      }
    } catch (error) {
      setData([]);
      setSnackbar({
        children: "Store does not have any orders. See the log.",
        severity: "warning",
      });

      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }

  async function handlePageChange(pagination) {
    setPaginationModel(pagination);

    if (activeButton) {
      await GetOrders(activeButton, pagination); // Re-fetch orders on pagination change
    }
  }
  const handleChange = async (event, newValue) => {
    setValue(newValue);
    await GetOrders(activeButton, paginationModel);
  };

  const columns = [
    {
      field: "name",
      headerName: "Order",
      width: 150,
      renderCell: (params) => (
        <>
          <a onClick={() => ViewOrderDetails(params.row.order_id)}>
            {params.value}
          </a>{" "}
        </>
      ),
    },
    { field: "shipping_name", headerName: "Name", width: 200 },
    {
      field: "financial_status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => {
        if (params.value === "paid") {
          return (
            <button className="magic-button magic-button-blue">
              {params.value}
            </button>
          );
        } else if (params.value === "unpaid") {
          return <span className="btn btn-sm btn-warning">{params.value}</span>;
        } else if (params.value === "pending") {
          return (
            <span className="magic-button magic-button-yellow">
              {params.value}
            </span>
          );
        } else {
          return (
            <span className="magic-button magic-button-red">
              {params.value}
            </span>
          );
        }
      },
    },
    {
      field: "isFulfilled",
      headerName: "Fulfillment",
      width: 100,

      renderCell: (params) =>
        params.value ? (
          <span className="magic-button magic-button-green">
            {params.row.fulfillment_status}
          </span>
        ) : (
          <span className="magic-button magic-button-yellow">
            Unfulfiled{params.value}
          </span>
        ),
    },
    {
      field: "payment_gateway_names",
      headerName: "GateWay",
      width: 200,
    },
    {
      field: "total_price",
      headerName: "Total",
      width: 100,
      align: "center",
    },
    {
      field: "total_outstanding",
      headerName: "Outstanding",
      width: 100,
      align: "center",
    },
    {
      field: "referring_site",
      headerName: "Refer",
      align: "center",
      width: 80,

      renderCell: (params) => {
        if (
          params.value === "https://www.google.com/" ||
          params.value ===
            "android-app://com.google.android.googlequicksearch" ||
          params.value === "android-app://com.google.android.gm/"
        ) {
          return <GoogleIcon />;
        } else if (
          params.value === "http://m.facebook.com/" ||
          params.value === "http://m.facebook.com" ||
          params.value === "https://l.facebook.com/" ||
          params.value === "https://lm.facebook.com/" ||
          params.value === "android-app://m.facebook.com/"
        ) {
          return <FacebookIcon />;
        } else if (
          params.value === "http://instagram.com/" ||
          params.value === "https://l.instagram.com/"
        ) {
          return <InstagramIcon />;
        } else if (params.value === "https://www.snapchat.com/") {
          return <ChatBubbleTwoToneIcon />;
        } else if (params.value === "https://www.tiktok.com/") {
          return <TikTokIcon color="green" />;
        } else if (!params.value) {
          return <AdsClickIcon />;
        } else {
          return <RssFeedIcon />;
        }
      },
    },
    { field: "shipping_city", headerName: "City", width: 150 },

    {
      field: "order_status_url",
      headerName: "URL",
      width: 100,
      renderCell: (params) => (
        <>
          <a href={params.value} target="_blank" rel="noreferrer">
            See Status
          </a>{" "}
        </>
      ),
    },
    {
      field: "total_qty",
      type: "actions",
      headerName: "Qty",
      width: 100,
      renderCell: (params) => (
        <>
          <span onClick={() => ViewOrder(params.row.order_id)}>
            {params.value}
          </span>{" "}
        </>
      ),
    },
    { field: "tags", headerName: "Tags", width: 250 },
    {
      field: "created_at",
      headerName: "C Date",
      width: 150,
      renderCell: (params) => {
        const date = new Date(params.value);
        return format(date, "dd/MM/yyyy hh:mm a");
      },
    },
  ];

  return (
    <div>
      <Tabs value={value} onChange={handleChange} centered>
        {/* <Tab icon={<FacebookIcon />} value="facebook" />
        <Tab label="Tiktok" value="tiktok" /> */}
        <Tab label="all" value="all" />
        <Tab label="awaiting approval" value="unfulfiled" />
        <Tab label="approved" value="fulfiled" />
        <Tab label="Confirmed" value="confirmed" />
        <Tab label="Dispatched" value="packed" />
        <Tab label="dispatch issue" value="error" />
        <Tab label="cancelled" value="cancelled" />
        <Tab label="returned" value="returned" />
      </Tabs>
      {data.length > 0 && (
        <>
          <div style={{ height: 900, width: "100%" }}>
            <DataGrid
              paginationModel={paginationModel}
              //onPaginationModelChange={setPaginationModel}

              onPaginationModelChange={handlePageChange}
              paginationMode="server"
              loading={loading}
              rowCount={rowCount}
              rows={data}
              columns={columns}
              rowHeight={28}
              getRowId={(row) => row.order_id}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default OrdersTable;
