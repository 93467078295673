// DataTable.js

import React, { useEffect, useState } from "react";
import axios from "axios";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
// const api = axios.create({
//   baseURL: process.env.REACT_APP_API,
// });
import api from "../hooks/apiClient";
const Orders = () => {
  const [data, setData] = useState([]);
  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    api
      .get("webhook/payloads/pending")
      .then((response) => {
        setData(response.data);
        console.log(response.data);
        setLoading(false); // Data has been fetched, set loading to false
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false); // Error occurred, set loading to false
      });
  }, []);
  const processOrder = async (id) => {
    try {
      const response = await api.get(`webhook/payload/${id}`);
      setSnackbar({
        children: "order Updated successfully",
        severity: "success",
      });
    } catch (error) {}
  };
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Snackbar
        open={snackbar ? true : false}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleCloseSnackbar}
        autoHideDuration={6000}
      >
        <Alert {...snackbar} onClose={handleCloseSnackbar} />
      </Snackbar>
      <h1>Webhooks</h1>
      {data &&
        data.map((item, index) => (
          <div
            className="card shadow p-1 mb-1 bg-body rounded"
            key={index}
            style={{ width: "100%", fontSize: 11 }} // 100% width
          >
            <div className="card-header d-flex justify-content-between  bg-body rounded">
              <button
                className="btn btn-success"
                onClick={() => processOrder(item.id)}
              >
                Proceed
              </button>
              <h6 className="card-title">{item.payload.name}</h6>
              <span style={{ fontSize: 11 }} className="card-subtitle">
                financial_status {item.payload.financial_status}
              </span>
              <span style={{ fontSize: 11 }} className="card-subtitle">
                {item.payload.id}
              </span>
              <span style={{ fontSize: 11 }} className="card-subtitle">
                total_line_items_price {item.payload.total_line_items_price}
              </span>
              <span style={{ fontSize: 11 }} className="card-subtitle">
                total_discounts {item.payload.total_discounts}
              </span>
              <span style={{ fontSize: 11 }} className="card-subtitle">
                total_outstanding {item.payload.total_outstanding}
              </span>
              <span style={{ fontSize: 11 }} className="card-subtitle">
                total_price {item.payload.total_price}
              </span>
              <span style={{ fontSize: 11 }} className="card-subtitle">
                Name{" "}
                {item.payload.shipping_address &&
                  item.payload.shipping_address.name}
              </span>
              <span style={{ fontSize: 11 }} className="card-subtitle">
                Mobile{" "}
                {item.payload.shipping_address &&
                  item.payload.shipping_address.phone}
              </span>
              <span style={{ fontSize: 11 }} className="card-subtitle">
                City
                {item.payload.shipping_address &&
                  item.payload.shipping_address.city}
              </span>
            </div>
            <div className="card-body">
              <div className="d-flex flex-wrap  ">
                {item.payload.line_item &&
                  item.payload.line_items.map((line_item, index) => (
                    <div
                      className="card shadow m-1 bg-body rounded"
                      key={index}
                    >
                      <div className="card-body">
                        <h6 className="card-title">{line_item.name}</h6>
                        <p className="card-text">
                          <span style={{ fontSize: 11 }}>
                            Price: {line_item.price}
                          </span>
                          &nbsp;
                          <span style={{ fontSize: 11, fontWeight: "bold" }}>
                            Qty: {line_item.quantity}
                          </span>
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Orders;
