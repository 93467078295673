import { useEffect } from "react";

import { useClientId } from "../authContext/ClientIdContext";

export function useRedirectIfUnauthorized() {
  const { clientId } = useClientId();

  useEffect(() => {
    if (clientId === 401 || !clientId) {
      window.location.href = "https://www.vnoirs.com/";
    }
  }, [clientId]);
}
