import React, { useEffect, useState } from "react";

import axios from "axios";

import HorizontalTimeline from "../components/HorizontalTimeline";
import api from "../hooks/apiClient";
// const api = axios.create({
//   baseURL: process.env.REACT_APP_API,
// });
const InventoryLogs = () => {
  const [data, setData] = useState([]);
  const [couriers, setCouriers] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [realTimeData, setRealTimeData] = useState([]);
  const [fileData, setFileData] = useState(null);
  const [stop, setStop] = useState(null);
  const [selectedStoreId, setSelectedStoreId] = useState("");
  const [selectedCourierId, setselectedCourierId] = useState("");

  useEffect(() => {
    async function getData() {
      const res = await api.get("inventory/logs");
      setData(res.data);
      console.log(res);
    }
    getData();
  }, []);

  return (
    <div>
      <HorizontalTimeline events={data} />
    </div>
  );
};

export default InventoryLogs;
