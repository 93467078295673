import React, { useMemo, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import SelectCourier from "../components/SelectCourier";
import Select from "../components/Select";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import CircularProgress from "@mui/material/CircularProgress";

import api from "../hooks/apiClient";
import QRCodeScanner from "../components/cameraScan";

const LoadSheet = () => {
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [showScanner, setShowScanner] = useState(false);
  const [dataQr, setDataQr] = useState(null); // Set initial data as null
  const [couriers, setCouriers] = useState([]);
  const [data, setData] = useState([]);
  const [fillData, setFillData] = useState([]);
  const [scannedData, setScannedData] = useState([]);
  const [stores, setStores] = useState([]);
  const [selectedCourier, setSelectedCourier] = useState("");
  const [selectedStore, setSelectedStore] = useState("");
  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  const handleShow = () => {
    setShow((prevShow) => !prevShow);
  };

  const fetchStoresCouriers = async (store_id) => {
    setLoading(true);
    try {
      const couriersResponse = await api.get("couriers/all/bystore", {
        params: {
          store_id: store_id,
        },
      });
      setCouriers(couriersResponse.data);
    } catch (err) {
      console.error("Error fetching stores and couriers:", err);
    } finally {
      setLoading(false);
    }
  };
  const dataMap = useMemo(() => {
    return data.reduce((acc, row) => {
      acc[row.CN] = row;
      return acc;
    }, {});
  }, [data]);

  const handleScan = (result) => {
    if (result && Array.isArray(result) && result.length > 0) {
      setDataQr(result);
      const qr = result[0].rawValue;
      const avl = dataMap[qr];
      // const avl = data.find((c) => c.CN === qr);
      const alreadyScanned = scannedData.find((c) => c.CN === qr);

      if (alreadyScanned) {
        alert("Item already scanned");
      } else if (avl) {
        setScannedData((prevData) => [...prevData, avl]);
      } else {
        alert("Item not found");
      }
    } else {
      alert("No valid scan result");
    }
  };
  const updateFillData = () => {
    const scannedIds = new Set(scannedData.map((item) => item.CN));
    setFillData(data.filter((item) => !scannedIds.has(item.CN)));
  };
  useEffect(() => {
    api
      .get("stores/all")
      .then((response) => {
        setStores(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    updateFillData();
  }, [data, scannedData]);

  const handleSelectChange = (value) => {
    setSelectedStore(value);
    if (value) {
      fetchStoresCouriers(value);
    } else {
      setCouriers([]);
    }
  };
  const handleSelectChangeC = (value) => {
    setSelectedCourier(value);
    console.log(selectionModel);
  };
  const addLoadSheet = async () => {
    try {
      setLoading(true);
      const respdata = await api.get("couriers/loadsheet/bystore", {
        params: {
          store_id: selectedStore,
          courier_id: selectedCourier,
        },
      });
      setData(respdata.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const columns = [
    { field: "RefNo", headerName: "Order", width: 150 },
    { field: "CN", headerName: "CN", width: 150 },
    { field: "Consignee", headerName: "Name", width: 150 },
    { field: "Pcs", headerName: "Pcs", width: 100 },
    { field: "CodAmount", headerName: "COD", width: 150 },
    { field: "BookingDate", headerName: "Date", width: 150 },
    {
      field: "Destination",
      headerName: "City",
      width: 250,
    },
  ];
  return (
    <div className="container mt-2">
      {showScanner && (
        <div
          className="position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 1050 }}
        >
          <div
            className="bg-white p-3 rounded shadow-lg"
            style={{ width: "80%", maxWidth: "500px" }}
          >
            <QRCodeScanner
              showScanner={showScanner}
              handleScan={handleScan}
              dataQr={dataQr}
              setShowScanner={setShowScanner}
            />
          </div>
        </div>
      )}
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}

      <Grid item xs={12}>
        <Grid item xs={8} xl={4}>
          <div className="shadow p-1 mb-1 bg-body rounded d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center gap-2">
              <Select
                stores={stores}
                value={selectedStore}
                onChange={handleSelectChange}
              />

              <SelectCourier
                couriers={couriers}
                value={selectedCourier}
                onChange={handleSelectChangeC}
              />
            </div>
            <span>
              Total {data.length} / {scannedData.length}
            </span>
            {selectedStore && selectedCourier && (
              <div className="d-flex align-items-center gap-2">
                <button
                  className="btn btn-sm btn-outline-dark"
                  onClick={addLoadSheet}
                >
                  Get LoadSheet Today
                </button>
                {loading && (
                  <div>
                    {" "}
                    <CircularProgress />
                  </div>
                )}
                <button
                  className="btn btn-sm btn-outline-success"
                  onClick={() => {
                    setShowScanner(true);
                    handleShow();
                  }}
                >
                  Open Scanner
                </button>
                <button
                  className="btn btn-sm btn-outline-success"
                  onClick={() => {
                    handleShow();
                  }}
                >
                  {show ? "Show Orders" : "Show Scanned"}
                </button>
              </div>
            )}
          </div>
        </Grid>

        {!show && (
          <Grid xs={12}>
            {!show && data.length > 0 && (
              <div style={{ height: 1000, width: "100%" }}>
                <DataGrid
                  rows={fillData}
                  loading={loading}
                  columns={columns}
                  pageSize={5}
                  getRowId={(row) => row.CN}
                  checkboxSelection
                  selectionModel={selectionModel}
                  onRowSelectionModelChange={(ids) => {
                    setSelectionModel(ids);
                    const selectedIDs = new Set(ids);
                    const selectedRows = data.filter((row) =>
                      selectedIDs.has(row.CN)
                    );
                    setScannedData((prev) => {
                      const combined = [...prev, ...selectedRows];
                      const uniqueItems = Array.from(
                        new Map(
                          combined.map((item) => [item.CN, item])
                        ).values()
                      );
                      return uniqueItems;
                    });

                    // setScannedData((prev) => [...prev, ...selectedRows]);
                  }}
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                />
              </div>
            )}
          </Grid>
        )}
        <Grid xs={12}>
          {show && scannedData.length > 0 && (
            <div style={{ height: 1000, width: "100%" }}>
              <DataGrid
                rows={scannedData}
                loading={loading}
                columns={columns}
                pageSize={5}
                getRowId={(row) => row.CN}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
              />
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default LoadSheet;
