import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import CusPopover from "../components/Popover";
// const api = axios.create({
//   baseURL: process.env.REACT_APP_API,
// });
import api from "../hooks/apiClient";
const VariantDetails = () => {
  const { sku } = useParams();
  const [data, setData] = useState();

  const [totalQty, setTotalQty] = useState("0");
  const [loading, setLoading] = useState(true);

  async function getData() {
    let qtyTotal = 0;
    try {
      const response = await api.get(`inventory/logs/${sku}`);
      setData(response.data);
      const transactions = response.data;
      transactions.forEach((transaction) => {
        qtyTotal += transaction.quantity;
      });
      setTotalQty(qtyTotal);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching product data:", error);
      setLoading(false);
    }
  }
  useEffect(() => {
    getData();
  }, [sku]);

  const columns = [
    { field: "id", headerName: "ID", width: 150 },
    { field: "store_id", headerName: "Store", width: 150 },
    { field: "order_id", headerName: "Order ID", width: 150 },
    { field: "variant_id", headerName: "Variant", width: 150 },
    { field: "sku", headerName: "SKU", width: 200 },
    { field: "log_type", headerName: "Type", width: 150 },
    { field: "quantity", headerName: "QTY", width: 150 },
    {
      field: "store_sync_status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => <CusPopover cellValue={params.value} />,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 250,
      renderCell: (params) => (
        <span>{new Date(params.value).toLocaleDateString()}</span>
      ),
    },
  ];
  const handleRowClick = (params) => {
    const { row } = params;
    const { id } = row;

    // navigate(`/productdetails/${id}`);
  };

  if (loading) {
    return (
      <div>
        {" "}
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="d-flex flex-wrap justify-content-evenly">
      <div className="shadow-sm p-3 bg-body rounded p-1 d-flex d-flex justify-content-between">
        Qty Total {totalQty}
      </div>
      <div style={{ height: 1000, width: "100%" }}>
        <DataGrid
          rowHeight={25}
          rows={data}
          columns={columns}
          pageSize={5}
          getRowId={(row) => row.id}
          onRowClick={handleRowClick}
          disableRowSelectionOnClick
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
        />
      </div>
    </div>
  );
};

export default VariantDetails;
