import React, { useEffect, useState } from "react";
import { LineChart } from "@mui/x-charts/LineChart";
const xLabels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export default function MyLineChart({ data }) {
  if (!data || !data.orderData || !data.storeLabels) {
    console.log("Data is missing or improperly structured:", data);
    return <div>loading</div>;
  }
  const dataN = data.orderData;
  const dataLabel = data.storeLabels;

  const series = Object.keys(dataN).map((storeId) => ({
    data: dataN[storeId], // The data array for the store
    label: dataLabel[storeId], // The label for the store
  }));

  return (
    <LineChart
      height={400}
      lineData
      series={series}
      xAxis={[{ scaleType: "point", data: xLabels }]}
    />
  );
}
