import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import EditIcon from "@mui/icons-material/Edit";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import VariantEdit from "../components/VariantEdit";
import CircularProgress from "@mui/material/CircularProgress";
import Select from "react-select";
// const api = axios.create({
//   baseURL: process.env.REACT_APP_API,
// });
import api from "../hooks/apiClient";
const ProductDetails = () => {
  const { id } = useParams();
  const [productData, setProductData] = useState();
  const [clientStore, setClientStore] = useState([]);
  const [selectedClientStore, setSelectedClientStore] = useState(null);

  const [selectedVariant, setSelectedVariant] = useState({});
  const [newInventoryValues, setNewInventoryValues] = useState({});
  const [addFakeInv, setAddFakeInv] = useState(false);
  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(null);
  const ToggleOpen = (id) => {
    if (id) {
      const variant = productData.variants.find((variant) => variant.id === id);
      setSelectedVariant(variant);
      setOpen(true);
    } else {
      setSelectedVariant(null);
      setOpen(false);
    }
  };
  const handleChange = (selectedOption) => {
    setSelectedClientStore(selectedOption);
  };

  async function getData() {
    try {
      const response = await api.get(`products/find/${id}`);
      const response2 = await api.get(`stores/find/clients`);
      const product = response.data;
      // console.log(product);
      const totalQuantity = product.variants.reduce(
        (acc, variant) => acc + variant.inventory_quantity,
        0
      );
      const totalShopify = product.variants.reduce(
        (acc, variant) => acc + variant.shopifyInventory,
        0
      );

      setProductData({ ...product, totalQuantity, totalShopify });

      const mkobj = makevalue(response2.data);
      setClientStore(mkobj);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching product data:", error);
      setLoading(false);
    }
  }
  useEffect(() => {
    getData();
  }, [id]);

  function makevalue(array) {
    return array.map((obj) => ({
      value: obj.id,
      label: obj.public_name,
      ...Object.fromEntries(
        Object.entries(obj).filter(
          ([key]) => key !== "id" && key !== "public_name"
        )
      ),
    }));
  }
  const MapThisProduct = async () => {
    setLoading(true);
    try {
      const response = await api.post(`products/single/map/${id}`, {
        client_store: selectedClientStore.value,
      });
      setSnackbar({ children: response.data.message, severity: "success" });
    } catch (error) {
      setSnackbar({ children: error.response.data.message, severity: "error" });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const handleInventoryChange = (inv_id, newValue) => {
    setNewInventoryValues((prev) => ({
      ...prev,
      [inv_id]: newValue,
    }));
  };
  const saveInventory = async () => {
    setLoading(true);
    try {
      const response = await api.put(`inventory/fake/adjust/${id}`, {
        newInventoryValues,
      });
      setSnackbar({
        children: response.data,
        severity: "success",
      });
      setNewInventoryValues({});
      setTimeout(() => {
        setAddFakeInv(false);
        getData();
      }, 2000);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching product data:", error);
      setLoading(false);
    }
  };
  async function addFakeInvBtn() {
    try {
      setAddFakeInv(true);
    } catch (error) {}
  }
  if (loading || !productData) {
    return (
      <div>
        {" "}
        <CircularProgress />
      </div>
    );
  }
  async function SyncUpdateProduct() {
    setLoading(true);
    try {
      const response = await api.put(`products/single/syncUpdate/${id}`);
      setSnackbar({ children: response.data.message, severity: "success" });
    } catch (error) {
      setSnackbar({ children: error.response.data.message, severity: "error" });
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="containers">
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
      <VariantEdit
        key={Date.now()}
        ToggleOpen={ToggleOpen}
        open={open}
        variant={selectedVariant}
      />
      {/* <SentDeliveryDialoag
        ToggleOpen={ToggleOpenDelivery}
        open={open}
        SendDelivery={() => SendDelivery()}
      /> */}
      <div className="shadow-sm p-3 mb-3 bg-body rounded d-flex flex-wrap d-flex justify-content-around">
        <div className="shadow p-3 mb-3 bg-body rounded ">
          <h4>Media</h4>

          <img
            src={productData.image_src}
            className="card-img-top"
            alt="Product"
            height="200"
          />
        </div>
        <div className="">
          <span
            className={`btn btn-sm btn-${
              productData.status === "active" ? "info" : "danger"
            }`}
          >
            {" "}
            Status: {productData.status}
          </span>
          <div className="shadow-sm p-3 mb-3 bg-body rounded">
            <input className="form-control" value={productData.title} />
          </div>
          <div className="shadow-sm p-3 mb-3 bg-body rounded d-flex  flex-column">
            <h5>Variants</h5>
            {/* <div className="d-flex justify-content-evenly shadow-sm p-1 mb-1 bg-body rounded">
            <button
              className={`btn btn-sm btn-outline-dark ${
                addFakeInv ? "active" : ""
              }`}
              onClick={addFakeInvBtn}
            >
              Add Fake Inventory
            </button>
            {addFakeInv && (
              <button
                className="btn btn-sm btn-outline-success "
                onClick={saveInventory}
              >
                Save
              </button>
            )}
          </div> */}
            <hr />

            <div className="shadow-sm bg-body rounded d-flex justify-content-around">
              <p>Name</p>
              <p>Qty</p>
              <p>Shopify Qty</p>
              {/* <p>FakeQty</p>
            <p>New Enter</p> */}
              <p>Action</p>
            </div>
            {productData.variants.map((variant, index) => (
              <div
                className="p-1 d-flex justify-content-between"
                key={variant.id}
              >
                <Link
                  to={`/variant/${variant.sku}`}
                  className="form-control p-1 btn btn-sm btn-link"
                >
                  {" "}
                  {variant.title} <i class="bi bi-chevron-right"></i>
                </Link>
                <input
                  value={variant.inventory_quantity}
                  className="form-control  p-1"
                  disabled
                />
                <input
                  value={variant.shopifyInventory}
                  className="form-control ms-1 p-1"
                  disabled
                />
                {/* <input
                value={variant.fake_inventory}
                className="form-control  p-1"
                disabled
              /> */}

                {addFakeInv && (
                  <input
                    className="form-control p-1"
                    type="number"
                    value={newInventoryValues[variant.inventory_item_id] || ""}
                    onChange={(e) =>
                      handleInventoryChange(
                        variant.inventory_item_id,
                        parseInt(e.target.value)
                      )
                    }
                  />
                )}
                <button
                  className="form-control p-1 btn btn-sm btn-outline-info ms-1"
                  onClick={() => ToggleOpen(variant.id)}
                >
                  <EditIcon />
                </button>
              </div>
            ))}
            <div className="shadow-sm p-1 bg-body rounded d-flex justify-content-around">
              Quantity : {productData.totalQuantity}, Shop Quantity :{" "}
              {productData.totalShopify}
            </div>
          </div>
        </div>

        <div className="shadow p-3 mb-3 bg-body rounded ">
          <div className="d-flex  flex-column">
            <spna className="shadow-sm p-2 mb-1 bg-body rounded">
              Mapped Details
            </spna>
            <button
              className="btn btn-sm btn-outline-secondary"
              onClick={() => SyncUpdateProduct()}
            >
              Sync this Product
            </button>
          </div>
        </div>
      </div>
      <div className="shadow p-2 mb-1 bg-body rounded d-flex floating-button-end">
        <button
          className="btn btn-sm btn-outline-info me-1"
          onClick={() => MapThisProduct()}
        >
          Map This Product
        </button>
        <div>
          <Select
            isLoading={isLoading}
            value={selectedClientStore}
            onChange={handleChange}
            options={clientStore}
            isSearchable={true}
            placeholder="Select a store"
          />
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
