import React, { useRef, useEffect } from "react";
import JsBarcode from "jsbarcode";
import { QRCodeSVG } from "qrcode.react";
const logos = {
  PostEx: "https://merchant.postex.pk/assets/images/PostEx.png",
  "M-P-Logistic":
    "https://cdn.shopify.com/s/files/1/0578/1228/7660/files/logo_23487f8c-8f67-4297-8339-980733e8ef64.png?v=1675160416",
  Trax: "https://cdn.shopify.com/s/files/1/0578/1228/7660/files/trax_logo_new.png?v=1699016625",
  "Swyft Logistics":
    "https://cdn.shopify.com/s/files/1/0578/1228/7660/files/swyft-logo-4FC4B04B60-seeklogo.com.png?v=1691651717",
  Other:
    "https://cdn.shopify.com/s/files/1/0578/1228/7660/files/1658136877370_Background_Removed.png?v=1727265148",
};
const OrderPage = ({ order, store }) => {
  const barcodeRefOrder = useRef(null);
  const barcodeRefCN = useRef(null);
  const barcodeRefOrder1 = useRef(null);
  const barcodeRefCN1 = useRef(null);

  const orderBarcode = {
    format: "code128",
    lineColor: "#000000",
    width: 1,
    height: 20,
    displayValue: true,
    fontSize: 10,
  };
  const orderBarcode1 = {
    format: "code128",
    lineColor: "#000000",
    width: 1,
    height: 20,
    displayValue: true,
    fontSize: 10,
  };
  const barcodeOptionsTracking = {
    format: "code128",
    lineColor: "#000000",
    width: 1.7,
    height: 40,
    displayValue: true,
    fontSize: 10,
  };
  const barcodeOptionsTracking1 = {
    format: "code128",
    lineColor: "#000000",
    width: 1,
    height: 20,
    displayValue: true,
    fontSize: 10,
  };
  const lastFulfillment = order.fulfillments[order.fulfillments.length - 1];

  useEffect(() => {
    if (barcodeRefOrder.current) {
      JsBarcode(barcodeRefOrder.current, order.name, orderBarcode);
    }
    if (barcodeRefOrder1.current) {
      JsBarcode(barcodeRefOrder1.current, order.name, orderBarcode1);
    }
    if (barcodeRefCN.current) {
      JsBarcode(
        barcodeRefCN.current,
        lastFulfillment.tracking_number,
        barcodeOptionsTracking
      );
    }
    if (barcodeRefCN1.current) {
      JsBarcode(
        barcodeRefCN1.current,
        order.fulfillments[0].tracking_number,
        barcodeOptionsTracking1
      );
    }
  }, [order, orderBarcode, barcodeOptionsTracking]);

  const qrCodeTracking = {
    value: lastFulfillment.tracking_number,
    size: 80,
    level: "H",
  };
  const qrCodeOrder = {
    value: store.qr_link,
    size: 65,
    level: "H",
  };

  return (
    <div className="print-page">
      <div className="d-flex flex-column">
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-between">
            <span className="font">
              <span className="fontS text-start ms-5">
                <img
                  className="ms-5"
                  src={store.logo_link}
                  alt="Item Image"
                  style={{ width: "100px", height: "auto" }}
                />
              </span>
              <br />
              Order No. <svg ref={barcodeRefOrder}></svg>
            </span>

            <span className="mt-4">
              <QRCodeSVG {...qrCodeTracking} />
            </span>
            <span className="d-flex flex-column">
              <span className="me-5" style={{ "margin-left": "120px" }}>
                <img
                  src={logos[lastFulfillment.tracking_company]}
                  alt={lastFulfillment.tracking_company}
                  style={{ width: "50px", height: "auto" }}
                />
              </span>

              <span>
                {" "}
                CN <svg ref={barcodeRefCN}></svg>
              </span>
            </span>
          </div>
          <hr />
          <div className="d-flex justify-content-between ">
            <div className="d-flex flex-column ">
              <td className=" fontB">{order.shipping_address.name}</td>
              <td className="fontB">{order.shipping_address.address1}</td>
              <td className="fontB">{order.shipping_address.city}</td>
              <td className="fontB">{order.shipping_address.phone}</td>
              <span className="mt-3">
                <QRCodeSVG {...qrCodeOrder} />
                <p className="mt-1 fontB">
                  Scan Above QR Code
                  <br />
                  To Get Discount On Your Next Purchase
                </p>
              </span>
            </div>
            <div className="ms-5 d-flex flex-column me-10">
              <div className="d-flex flex-row justify-content-between">
                <span>
                  <span className="fontS ">Order No</span>
                  <br />
                  <span className="fontB">{order.name}</span>
                </span>
                <div>
                  <span className="fontS">weight</span>
                  <br />
                  <span className="fontB">100 Grams</span>
                </div>
                <div>
                  <span className="fontS">Payment</span>
                  <br />
                  <span className="fontB"> COD</span>
                </div>
                <div>
                  <span className="fontS">Total Items</span>
                  <br />
                  <span className="fontB"> {order.products}</span>
                </div>
              </div>

              <div className="d-flex flex-row justify-content-between">
                <div>
                  <span className=" fontS">Order Date</span>
                  <br />
                  <span className="fontB">
                    {new Date(order.created_at).toLocaleDateString("en-US")}
                  </span>
                </div>
                <div>
                  <span className="fontS">Location</span>
                  <br />
                  <span className="fontB"> Warehouse</span>
                </div>
                <div>
                  <span className="fontS">CN Type</span>
                  <br />
                  <span className="fontB">COD</span>
                </div>
                <div>
                  <span className="fontS">Shipper</span>
                  <br />
                  <span className="fontB"> Velvour</span>
                </div>
              </div>

              <div className="d-flex flex-row justify-content-between">
                <span className="border mb-1 bg-body-tertiary rounded">
                  <span className="fontL ms-1">
                    <b>COD Amount:</b>
                  </span>
                  <span className="ms-4 me-2 fontL">
                    <b>Rs. {order.total_outstanding}</b>
                  </span>
                </span>
              </div>
              <div className=" d-flex flex-column fontS  ">
                <span className="fontB text-start">Shipper address</span>
                <span className="text-start">
                  Velvour ,Warehouse street eid gah road near kider garden
                  school ,Faislabad
                </span>
                <span className="text-start">Mobile: 0321 8666602</span>
              </div>
            </div>
          </div>
        </div>
        <hr className="dotted-line " />

        <div>
          <div className="fontS d-flex flex-row justify-content-between">
            <span>
              {" "}
              Order No <svg ref={barcodeRefOrder1}></svg>
            </span>
            <span>
              {" "}
              CN <svg ref={barcodeRefCN1}></svg>
            </span>
          </div>
          <table style={{ width: "100%" }}>
            <thead>
              <tr style={{ fontSize: "11px" }}>
                <th>Pic</th>
                <th>Quantity</th>
                <th>Item</th>
                <th>SKU</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody className="">
              {order.fulfillments.map((fulfillment) =>
                fulfillment.line_items.map((item) => (
                  <tr className="fontS" key={item.id}>
                    <td>
                      <img
                        src={store.logo_link}
                        alt="Item Image"
                        style={{ width: "30px", height: "30px" }}
                      />
                    </td>
                    <td className="fontB">{item.quantity}</td>
                    <td>
                      {item.name} .. (<b>{item.quantity}</b>)
                    </td>
                    <td>SKU27542936544423/4</td>
                    <td>
                      {item.original_price !== item.price && (
                        <s>{item.original_price}</s>
                      )}
                      {item.price}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>

          <div style={{ float: "right" }}>
            <table>
              <tbody>
                <tr className="fontB">
                  <td>Subtotal price:</td>
                  <td>{order.subtotal_price}</td>
                </tr>
                <tr className="fontB">
                  <td>Shipping:</td>
                  <td>{order.shipping_lines}</td>
                </tr>
                <tr className="fontB">
                  <td>Total price:</td>
                  <td>{order.total_outstanding}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <br />
          <br />

          <span style={{ fontSize: "9px" }}>
            If you have any questions, please send an email to{" "}
            <u>velvour.official@gmail.com</u>
          </span>
        </div>
        <span className="fontS">
          Print Date {new Date().toLocaleDateString("en-US")}
        </span>
      </div>
    </div>
  );
};

export default OrderPage;
