import * as React from "react";
import { PieChart } from "@mui/x-charts/PieChart";

export default function MyPieChart({ data }) {
  return (
    <PieChart
      series={[
        {
          data,
        },
      ]}
      height={350}
    />
  );
}
