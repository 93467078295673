import React, { useState } from "react";
import axios from "axios";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { InputLabel } from "@mui/material";
const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});
const VariantEdit = ({ ToggleOpen, open, variant, authToken }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [linkPaste, setLinkPaste] = useState("");
  const [msg, setMsg] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [linkOpen, setLinkOpen] = useState(false);

  if (!open) return null;
  // const saveInventory = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await api.put(`inventory/fake/adjust/${id}`, {
  //       newInventoryValues,
  //     });
  //     setSnackbar({
  //       children: response.data,
  //       severity: "success",
  //     });
  //     setNewInventoryValues({});
  //     setTimeout(() => {
  //       setAddFakeInv(false);
  //       getData();
  //     }, 2000);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching product data:", error);
  //     setLoading(false);
  //   }
  // };
  // async function addFakeInvBtn() {
  //   try {
  //     setAddFakeInv(true);
  //   } catch (error) {}
  // }
  return (
    <div>
      <Dialog
        open={open}
        keepMounted
        onClose={() => ToggleOpen(null)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {`ID: ${variant.id}`} {variant.title}
        </DialogTitle>

        <DialogContent>
          <div className="p-1 d-flex flex-column">
            <div className="p-1 d-flex ">
              <InputLabel htmlFor="qty">
                QTY
                <input
                  value={variant.inventory_quantity}
                  className="form-control  p-1"
                  disabled
                  name="qty"
                />
              </InputLabel>
              <InputLabel htmlFor="amount">
                Amount
                <input
                  value={variant.price}
                  className="form-control  p-1"
                  disabled
                  name="amount"
                />
              </InputLabel>
              <InputLabel htmlFor="compareprice">
                compare price
                <input
                  value={variant.compare_at_price}
                  className="form-control  p-1"
                  disabled
                  name="compareprice"
                />
              </InputLabel>
            </div>
            <div className="p-1 d-flex justify-content-around">
              <InputLabel htmlFor="compareprice">
                SKU
                <input
                  value={variant.sku}
                  className="form-control  p-1"
                  disabled
                />
              </InputLabel>
              <InputLabel htmlFor="compareprice">
                BarCode
                <input
                  value={variant.barcode}
                  className="form-control  p-1"
                  disabled
                />
              </InputLabel>
              <InputLabel htmlFor="compareprice">
                Fake
                <input
                  value={variant.fake_inventory}
                  className="form-control  p-1"
                  disabled
                />
              </InputLabel>
            </div>
          </div>
          <LoadingButton
            className="mt-2"
            size="small"
            color="primary"
            // onClick={handleImageUpload}
            loading={uploading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            disabled={!selectedImage || uploading}
          >
            <span>Save</span>
          </LoadingButton>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => ToggleOpen(null)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default VariantEdit;
