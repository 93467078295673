import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Switch from "@mui/material/Switch";
import Select from "react-select";
import api from "../hooks/apiClient";
const options = [
  { value: "source", label: "source" },
  { value: "destination", label: "destination" },
];

// const api = axios.create({
//   baseURL: process.env.REACT_APP_API,
// });

const SourceStores = ({ authToken }) => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);
  const [storesData, setStoresData] = useState([]);
  const [clientStores, setClientStores] = useState([]);
  const [viewSet, setViewSet] = useState(true);
  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  const [formData, setFormData] = useState({
    publicName: "",

    shopName: "",
    shopType: "",
    orderPrefix: "",
    shopifyDomain: "",
    storeApi: "",
    apiSecret: "",
    apiToken: "",
    apiVersion: "",
    locationId: "",
  });
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleChangeType = (opt) => {
    setSelectedOption(opt);
    setFormData({ ...formData, shopType: opt.value });
  };

  const viewStores = () => {
    setViewSet((prevViewSet) => !prevViewSet);
    if (storesData.length === 0) {
      setSnackbar({
        children: "There is no store to show add  a new store",
        severity: "warning",
      });
    }
  };

  useEffect(() => {
    api
      .get("stores", {
        headers: {
          token: authToken,
        },
      })
      .then((response) => {
        setStoresData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    api
      .get("stores/clients", {
        headers: {
          token: authToken,
        },
      })
      .then((response) => {
        setClientStores(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedOption) {
      setSnackbar({
        children: "Please Select A type of store carefully ",
        severity: "info",
      });
      return null;
    }
    try {
      const response = await api.post(`stores/add`, formData, {
        headers: {
          "Content-Type": "application/json",
          token: authToken,
        },
      });
      setFormData({
        publicName: "",
        shopName: "",
        shopType: "",
        orderPrefix: "",
        shopifyDomain: "",
        storeApi: "",
        apiSecret: "",
        apiToken: "",
        apiVersion: "",
        locationId: "",
      });
      setSnackbar({
        children: response.data.message,
        severity: "success",
      });
    } catch (error) {
      console.error("Submit Failed:", error);
      setSnackbar({ children: error.message, severity: "error" });
    }
  };
  async function storeActive(id, cIsActive) {
    setSnackbar({
      children: `${id} is ${cIsActive}`,
      severity: "success",
    });
  }
  const handleRowClick = (id) => {
    navigate(`/storesdetails/${id}`);
  };

  if (viewSet && storesData.length > 0) {
    return (
      <div className="container mt-5">
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
        <button
          type="button"
          className="btn btn-sm btn-outline-secondary float-end"
          onClick={viewStores}
        >
          <i className="bi bi-gear"></i> &nbsp; Add New Store
        </button>
        <br />
        <br />
        <div className="d-flex flex-column justify-content-center">
          <div className="d-flex  justify-content-center">
            <h6 className="fw-bold w-auto shadow-lg p-3 mb-2 rounded bg-body">
              Source Stores
            </h6>
          </div>
          {storesData &&
            storesData.map((store) => (
              <div
                key={store.id}
                className=" shadow-sm p-2 mb-1 bg-body rounded"
              >
                <div className=" d-flex flex-wrap justify-content-between">
                  <Switch
                    checked={store.isActive}
                    onChange={() => storeActive(store.id, store.isActive)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <p>Name: {store.public_name}</p>

                  <p>Domain: {store.domain}</p>

                  <p>Status: {store.status}</p>
                </div>
                <button
                  className="btn btn-sm btn-outline-secondary"
                  onClick={() => handleRowClick(store.id)}
                >
                  View Details
                </button>
              </div>
            ))}
        </div>
        <div className="d-flex flex-column justify-content-center">
          <div className="d-flex  justify-content-center">
            <h6 className="fw-bold w-auto shadow-lg p-3 mb-2 rounded bg-body">
              Client Stores
            </h6>
          </div>
          {clientStores &&
            clientStores.map((store) => (
              <div
                key={store.id}
                className=" shadow-sm p-2 mb-1 bg-body rounded"
              >
                <div className=" d-flex flex-wrap justify-content-between">
                  <Switch
                    checked={store.isActive}
                    onChange={() => storeActive(store.id, store.isActive)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <p>Name: {store.public_name}</p>

                  <p>Domain: {store.domain}</p>

                  <p>Status: {store.status}</p>
                </div>
                <button
                  className="btn btn-sm btn-outline-secondary"
                  onClick={() => handleRowClick(store.id)}
                >
                  View Details
                </button>
              </div>
            ))}
        </div>
      </div>
    );
  }
  return (
    <div className="container mt-5">
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
      <div className="d-flex justify-content-center ">
        <div className="shadow-lg p-3 mb-5 bg-body rounded col-lg-6 ">
          <button
            type="button"
            className="btn btn-sm btn-outline-secondary float-end"
            onClick={viewStores}
          >
            <i className="bi bi-eye"></i> &nbsp; View Stores
          </button>
          <br />
          <br />
          <p>
            Please enter all information correct it can not be undo and it will
            conume your store limits
          </p>
          <h4 className="mb-5 text-center">Shopify Store Register</h4>

          <div className="">
            <Select
              isLoading={false}
              value={selectedOption}
              onChange={handleChangeType}
              options={options}
              isSearchable={true}
              placeholder="Select a Type"
            />
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="publicName" className="form-label">
                  Shop Public Name
                </label>
                <input
                  required
                  autoComplete="off"
                  className="form-control "
                  type="text"
                  placeholder="Public Name..."
                  name="publicName"
                  value={formData.publicName}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="shopName" className="form-label">
                  Shopify Registerd Shop Name
                </label>
                <input
                  required
                  autoComplete="off"
                  className="form-control "
                  type="text"
                  placeholder="Shop Name..."
                  name="shopName"
                  value={formData.shopName}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="shopifyDomain" className="form-label">
                  Sopify Domain name
                </label>

                <input
                  required
                  autoComplete="off"
                  className="form-control"
                  type="text"
                  placeholder="enter as yourstore.myshopify.com"
                  name="shopifyDomain"
                  value={formData.shopifyDomain}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="storeApi" className="form-label">
                  Store Api:
                </label>
                <input
                  required
                  placeholder="API Key.."
                  autoComplete="off"
                  className="form-control"
                  type="text"
                  name="storeApi"
                  value={formData.storeApi}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-3 ">
                <label htmlFor="apiSecret" className="form-label">
                  {" "}
                  Api Secret:
                </label>

                <input
                  required
                  placeholder="Api Secret..."
                  autoComplete="off"
                  className="form-control"
                  type="text"
                  name="apiSecret"
                  value={formData.apiSecret}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3 ">
                <label htmlFor="apiToken" className="form-label">
                  {" "}
                  Api Token:
                </label>

                <input
                  required
                  placeholder="API Token.."
                  autoComplete="off"
                  className="form-control"
                  type="text"
                  name="apiToken"
                  value={formData.apiToken}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3 ">
                <label htmlFor="apiVersion" className="form-label">
                  {" "}
                  Api Version:
                </label>

                <input
                  required
                  placeholder="API Version..."
                  autoComplete="off"
                  className="form-control"
                  type="text"
                  name="apiVersion"
                  value={formData.apiVersion}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3 ">
                <label htmlFor="orderPrefix" className="form-label">
                  {" "}
                  Order Prefix:
                </label>

                <input
                  required
                  placeholder="Order Prefix..."
                  autoComplete="off"
                  className="form-control"
                  type="text"
                  name="orderPrefix"
                  value={formData.orderPrefix}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3 ">
                <label htmlFor="locationId" className="form-label">
                  {" "}
                  location Id:
                </label>

                <input
                  required
                  placeholder="Location Id.."
                  autoComplete="off"
                  className="form-control"
                  type="number"
                  name="locationId"
                  value={formData.locationId}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <button className=" btn btn-success" type="submit">
                  Add A {selectedOption && selectedOption.value} Store
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SourceStores;
