import React from "react";
import "./HorizontalTimeline.css";

const HorizontalTimeline = ({ events }) => {
  return (
    <div className="timeline-container">
      <div className="timeline-rod"></div>
      {events.map((event, index) => (
        <div key={index} className="timeline-event">
          <div className="timeline-content">
            <p>{event.id}</p>
            <p>{event.store_id}</p>
            <p>{event.details ? event.details : event.log_type}</p>
            <p>{event.type ? event.type : event.quantity}</p>
            <p className="timeline-status">
              {event.status ? event.status : event.store_sync_status}
            </p>
            <p>{event.sku ? event.sku : ""}</p>
            <p>{event.variant_id ? event.variant_id : ""}</p>
            <span className="timeline-date">{event.createdAt}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default HorizontalTimeline;
