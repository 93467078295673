import { useState } from "react";
import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});
const RegesterPage = ({ authToken, clientId }) => {
  const [fullName, setFullName] = useState("");
  const [mobile, setMobile] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handlRegister = async (e) => {
    e.preventDefault();

    setFormErrors(validateForm());
    try {
      const response = await api.post("auths/register", {
        clientId,
        fullName,
        mobile,
        username,
        email,
        password,
        role,
      });

      setSuccess(
        "plz vist you email account to validate you email",
        response.data
      );
      setError("");
    } catch (error) {
      console.error("reg failed:", error);
      setError(error.response.data);
      setSuccess("");
    }
  };
  const validateForm = () => {
    const errors = {};
    if (!fullName) {
      errors.fullName = "Name is required.";
    }
    if (!mobile) {
      errors.mobile = "mobile is required.";
    }
    if (!username) {
      errors.username = "username is required.";
    }
    if (!email) {
      errors.email = "email is required.";
    }
    if (!password) {
      errors.password = "Password is required.";
    }
    if (!role) {
      errors.role = "role is required.";
    }
    return errors;
  };
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className="shadow p-5 mb-5 bg-body rounded">
        <h4>Register Page</h4>

        <form onSubmit={handlRegister}>
          <div className="form-group">
            <label className="form-label" htmlFor="fullname">
              User Role:
            </label>
            <select
              className="form-control"
              name="role"
              id="role"
              value={role}
              onChange={(e) => setRole(e.target.value)}
            >
              <option value="user">User</option>

              <option value="orders">Orders</option>
              <option value="ordersbooking">Order Booking</option>
              <option value="couriercity">Courier City</option>
              <option value="orderpay">Order Pay</option>
              <option value="products">Products</option>
              <option value="inventorytracking">Inventory</option>
            </select>

            {formErrors.role && <p>{formErrors.role}</p>}
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="fullname">
              Full Name:
            </label>
            <input
              required
              placeholder="Enter your name"
              className="form-control"
              type="text"
              id="fullname"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
            {formErrors.fullName && <p>{formErrors.fullName}</p>}
          </div>

          <div className="form-group">
            <label className="form-label" htmlFor="password">
              Mobile:
            </label>
            <input
              required
              className="form-control"
              type="mobile"
              id="mobile"
              placeholder="Enter your mobile"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            />
            {formErrors.mobile && (
              <p className="invalid-feedback">{formErrors.mobile}</p>
            )}
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="password">
              Email:
            </label>
            <input
              required
              className="form-control"
              type="email"
              id="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {formErrors.email && (
              <p className="invalid-feedback">{formErrors.email}</p>
            )}
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="username">
              Username:
            </label>
            <input
              required
              placeholder="Enter username"
              className="form-control"
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="password">
              Password:
            </label>
            <input
              required
              className="form-control"
              type="password"
              id="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </form>

        <br />
        {error && <p className="alert alert-danger">{error}</p>}
        {success && (
          <p className="alert alert-success fw-semibold">{success}</p>
        )}
        <button
          type="submit"
          className="btn btn-success"
          onClick={handlRegister}
        >
          Register
        </button>
      </div>
    </div>
  );
};

export default RegesterPage;
