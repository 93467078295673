import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import MyPieChart from "../chart/PieChart";
import MyLineChart from "../chart/LineChart";
import MyChart from "../chart/Chart";
import api from "../hooks/apiClient";
import MyDatePicker from "../components/DatePicker";
import dayjs from "dayjs";
export default function BarAnimation() {
  const [lineData, setLineData] = useState([]);
  const [pieData, setPieData] = useState([]);
  const [chartData, setChartData] = useState();

  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [selectedDateEnd, setSelectedDateEnd] = useState(dayjs());
  const handleDateChange = (date) => {
    setSelectedDate(date);
    // Ensure the end date is not earlier than the start date
    if (selectedDateEnd.isBefore(date)) {
      setSelectedDateEnd(date); // Adjust the end date to match the start date
    }
    fetchData(date, selectedDateEnd);
  };

  const handleDateChangeEnd = (date) => {
    if (date.isBefore(selectedDate)) {
      alert("End date cannot be earlier than the start date.");
      return; // Prevent the end date from being updated
    }
    setSelectedDateEnd(date);
    fetchData(selectedDate, date);
  };

  async function fetchData(
    startDate = selectedDate,
    endDate = selectedDateEnd
  ) {
    if (startDate.isSame(endDate)) {
      startDate = endDate.subtract(7, "day"); // Subtract 7 days if dates are the same
      setSelectedDate(startDate);
    }

    const response = await api.get("orders/all", {
      params: {
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
      },
    });

    setLineData(response.data.lineData);
    setPieData(response.data.pieData);
    setChartData(response.data.chartData);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box mt={6} sx={{ width: "100%" }}>
      <Grid container spacing={2} rowSpacing={5}>
        <Grid container item xs={12} md={12}>
          <Box my={1} display="flex" alignItems="center" gap={1} p={1}>
            <MyDatePicker
              selectedDate={selectedDate}
              onDateChange={handleDateChange}
              label="Pick Start date"
            />
            <MyDatePicker
              selectedDate={selectedDateEnd}
              onDateChange={handleDateChangeEnd}
              label="Pick End date"
            />
          </Box>
          <MyChart data={chartData} />;
        </Grid>
        <Grid item xs={12} md={6}>
          <MyLineChart data={lineData} />
        </Grid>
        <Grid xs={12} md={6}>
          <MyPieChart data={pieData} />
        </Grid>
      </Grid>
    </Box>
  );
}
