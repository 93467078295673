import React from "react";
import { useContext, useState, useEffect } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import { useLocation, Outlet, useNavigate, NavLink } from "react-router-dom";
import MyChart from "./pages/charts";

import { useRedirectIfUnauthorized } from "./hooks/ClientIdHook";
import { AuthContext } from "./authContext/AuthContext";
import { logout } from "./authContext/AuthActions";
const Dashboard = () => {
  useRedirectIfUnauthorized();
  const navigate = useNavigate();
  const location = useLocation();
  const { user, dispatch } = useContext(AuthContext);

  const handelBtn = (btn) => {
    if (btn === "Logout") {
      dispatch(logout());
      navigate(`/login`);
      //window.location.reload();
    }
    if (btn === "Profile") {
      navigate(`/users`);
    }
  };
  useEffect(() => {
    if (!user) {
      navigate(`/login`);
    }
  }, [user, useRedirectIfUnauthorized]);

  const userRole = user?.user.role;
  const isAdmin = user?.user.isAdmin;

  return (
    <div className="d-flex flex-nowrap ">
      <div className="col-auto px-0 bg-info">
        <div className="d-flex flex-column align-items-center justify-content-center align-items-sm-start px-2 text-white min-vh-100">
          <ul className="no-print nav nav-pills flex-column mb-sm mb-0 align-items-center align-items-sm-start">
            {isAdmin && (
              <li className="nav-item position-relative nav-link-hover  nav-link-hover">
                <NavLink
                  style={({ isActive, isPending, isTransitioning }) => ({
                    fontWeight: isActive ? "bold" : "",
                    color: isActive ? "red" : "white",
                    viewTransitionName: isTransitioning ? "slide" : "",
                  })}
                  to="/"
                  end
                >
                  <i className="fs-4 bi-house"></i>{" "}
                  <span className="ms-1 nav-text">Home</span>
                </NavLink>
              </li>
            )}
            {(userRole.includes("orders") || isAdmin) && (
              <li className="nav-item position-relative nav-link-hover">
                <NavLink
                  style={({ isActive, isPending, isTransitioning }) => ({
                    fontWeight: isActive ? "bold" : "",
                    color: isActive ? "red" : "white",
                    viewTransitionName: isTransitioning ? "slide" : "",
                  })}
                  to="/orders"
                  end
                >
                  <i className="fs-4 bi-border-style"></i>{" "}
                  <span className="ms-1   nav-text">Orders</span>
                </NavLink>
              </li>
            )}
            {(userRole.includes("products") || isAdmin) && (
              <li className="nav-item position-relative nav-link-hover">
                <NavLink
                  style={({ isActive, isPending, isTransitioning }) => ({
                    fontWeight: isActive ? "bold" : "",
                    color: isActive ? "red" : "white",
                    viewTransitionName: isTransitioning ? "slide" : "",
                  })}
                  to="/products"
                  end
                >
                  <i className="fs-4 bi-grid"></i>{" "}
                  <span className="ms-1   nav-text">Products</span>{" "}
                </NavLink>
              </li>
            )}
            {(userRole === "inventoryLogs" || isAdmin) && (
              <li className="nav-item position-relative nav-link-hover">
                <NavLink
                  style={({ isActive, isPending, isTransitioning }) => ({
                    fontWeight: isActive ? "bold" : "",
                    color: isActive ? "red" : "white",
                    viewTransitionName: isTransitioning ? "slide" : "",
                  })}
                  to="/inventoryLogs"
                  end
                >
                  <i className="fs-4 bi bi-blockquote-left"></i>{" "}
                  <span className="ms-1   nav-text">Inventory Logs</span>{" "}
                </NavLink>
              </li>
            )}
            {(userRole.includes("inventorytracking") || isAdmin) && (
              <li className="nav-item position-relative nav-link-hover">
                <NavLink
                  style={({ isActive, isPending, isTransitioning }) => ({
                    fontWeight: isActive ? "bold" : "",
                    color: isActive ? "red" : "white",
                    viewTransitionName: isTransitioning ? "slide" : "",
                  })}
                  to="/logs"
                  end
                >
                  <i className="fs-4 bi-book-fill"></i>{" "}
                  <span className="ms-1   nav-text">Logs</span>{" "}
                </NavLink>
              </li>
            )}
            {(userRole === "webhooks" || isAdmin) && (
              <li className="nav-item position-relative nav-link-hover">
                <NavLink
                  style={({ isActive, isPending, isTransitioning }) => ({
                    fontWeight: isActive ? "bold" : "",
                    color: isActive ? "red" : "white",
                    viewTransitionName: isTransitioning ? "slide" : "",
                  })}
                  to="/webhooks"
                  end
                >
                  <i className="fs-4 bi-browser-edge"></i>{" "}
                  <span className="ms-1   nav-text">Webhooks</span>
                </NavLink>
              </li>
            )}

            {isAdmin && (
              <li className="nav-item position-relative nav-link-hover">
                <NavLink
                  style={({ isActive, isPending, isTransitioning }) => ({
                    fontWeight: isActive ? "bold" : "",
                    color: isActive ? "red" : "white",
                    viewTransitionName: isTransitioning ? "slide" : "",
                  })}
                  to="/stores"
                  end
                >
                  <i className="fs-4 bi-shop"></i>
                  <span className="ms-1   nav-text">Stores</span>
                </NavLink>
              </li>
            )}
            {isAdmin && (
              <li className="nav-item position-relative nav-link-hover">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active" : "inActive"
                  }
                  to="/couriers"
                  end
                >
                  <i className="fs-4 bi-truck"></i>
                  <span className="ms-1   nav-text">Couriers</span>
                </NavLink>
              </li>
            )}
            {(userRole === "orders" || isAdmin) && (
              <li className="nav-item position-relative nav-link-hover">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active" : "inActive"
                  }
                  to="/orders/paid"
                  end
                >
                  <i className="fs-4 bi-credit-card-2-back"></i>
                  <span className="ms-1   nav-text">Orders PaY By Month</span>
                </NavLink>
              </li>
            )}
            <li className="nav-item mt-5 nav-link-hovers">
              <i class=" fs-4 bi-x-circle-fill"></i>

              <div className="nav-text-container">
                <button
                  className=" nav-texts"
                  onClick={() => handelBtn("Logout")}
                >
                  Logout
                </button>
                <button
                  className="nav-texts"
                  onClick={() => handelBtn("Profile")}
                >
                  Profile
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div
        className="text-center container-fluid "
        style={{ overflowY: "auto", maxHeight: "100vh" }}
      >
        {isAdmin && location.pathname === "/" && <MyChart />}

        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
