import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";

export default function OrdersPerDayPerStoreChart({ data }) {
  if (!data || !data.result || !data.xLabels) {
    console.log("Data is missing or improperly structured:", data);
    return <div>loading</div>;
  }
  return (
    <>
      <BarChart
        skipAnimation={false}
        height={400}
        series={data.result}
        xAxis={[
          {
            data: data.xLabels, // x-values (dates)
            scaleType: "band", // Use band scale for categorical x-axis
            label: "Date", // Label for the x-axis
          },
        ]}
        yAxis={[
          {
            label: "Order Count", // Label for the y-axis
          },
        ]}
      />
    </>
  );
}
