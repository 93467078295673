import React, { useEffect, useState } from "react";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import api from "../../hooks/apiClient";

const Clients = ({ authToken }) => {
  const [showInput, setShowInput] = useState("");
  const [courierId, setCourierId] = useState("");
  const [loading, setLoading] = useState(true);
  const [clientsData, setClientsData] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [domainName, setDomainName] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  useEffect(() => {
    const Users = async () => {
      try {
        const response = await api.get("clients/all", {
          headers: {
            token: authToken,
          },
        });

        setClientsData(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error making request:", error);
        setSnackbar({ children: error.message, severity: "error" });
      }
    };
    Users();
  }, []);
  const handlRegister = async (e) => {
    e.preventDefault();
    setFormErrors(validateForm());
    try {
      const response = await api.post(
        "clients/register",
        {
          domainName,
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: authToken,
          },
        }
      );

      setSuccess(response.data);
      setError("");
    } catch (error) {
      setError(error.message);
      setSuccess("");
    }
  };
  const validateForm = () => {
    const errors = {};
    if (!domainName) {
      errors.domainName = "Name is required.";
    }
    return errors;
  };
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };
  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };
  const assignCourier = (id) => async () => {
    setShowInput(id);
  };
  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };
  const processRowUpdate = async (newRow) => {
    try {
      const response = await api.put(
        `clients/update/${newRow.client_id}`,
        { newRow },
        {
          headers: {
            token: authToken,
          },
        }
      );
      console.log(response.data);
      setSnackbar({ children: response.data, severity: "success" });
    } catch (error) {
      console.error("Error making request:", error);
    }
    return newRow;
  };
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  async function submit() {
    try {
      const response = await api.put(
        `clients/assigncourier/${showInput}`,
        { courierId },
        {
          headers: {
            token: authToken,
          },
        }
      );
      setSnackbar({ children: response.data, severity: "success" });
      setShowInput("");
    } catch (error) {
      setLoading(false);
      console.error("Error making request:", error);
      setSnackbar({ children: error.message, severity: "error" });
    }
  }
  const columns = [
    {
      field: "client_id", // You can use a field that represents the image URL
      headerName: "ID",
      width: 150,
    },
    { field: "name", headerName: "Name", width: 150, editable: true },
    { field: "status", headerName: "Status", width: 150, editable: true },
    {
      field: "isActive",
      headerName: "Active",

      type: "boolean",
      width: 140,
      editable: true,
    },

    { field: "createdAt", headerName: "Date", width: 250 },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<LocalShippingIcon />}
            label="Add"
            onClick={assignCourier(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  if (loading) {
    return <div>Loading...</div>;
  }
  if (clientsData.length == 0) {
    return (
      <div>
        <div className="d-flex justify-content-center align-items-center vh-100">
          <div className="shadow p-3 mb-5 bg-body rounded">
            <h6>Register Subdomain</h6>
            <div className="">
              <form onSubmit={handlRegister}>
                <div className="form-group">
                  <label className="form-label" htmlFor="domainName">
                    Domain Name:
                  </label>
                  <input
                    required
                    placeholder="Enter subdomain"
                    className="form-control"
                    type="text"
                    id="domainName"
                    value={domainName}
                    onChange={(e) => setDomainName(e.target.value)}
                  />
                  {formErrors.domainName && <p>{formErrors.domainName}</p>}
                </div>
              </form>
            </div>
            <br />
            {error && <p className="alert alert-danger">{error}</p>}
            {success && (
              <p className="alert alert-success">
                <p className="fw-semibold"> Domain registerd Successfully</p>

                <p>{success.name}</p>
              </p>
            )}
            <button
              type="submit"
              className="btn btn-success"
              onClick={handlRegister}
            >
              Register
            </button>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="mt-2">
      <div>
        {showInput ? (
          <div className=" shadow p-3 mb-5 bg-body rounded d-flex justify-content-around">
            <input
              required
              autoComplete="off"
              className="form-control "
              type="number"
              placeholder="courier_id...."
              name="courier_id"
              value={courierId}
              onChange={(e) => setCourierId(e.target.value)}
            />

            <button
              className=" px-2 btn btn-success"
              type="button"
              onClick={submit}
            >
              Submit
            </button>
          </div>
        ) : null}
      </div>
      <h3>Clients</h3>
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
      <div style={{ height: 1000, width: "100%" }}>
        <DataGrid
          rows={clientsData}
          columns={columns}
          editMode="row"
          getRowId={(row) => row.client_id}
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={(error) => {
            console.error("Error processing row update:", error);
            // Optionally handle the error (e.g., show an error message)
            setSnackbar({ children: error.message, severity: "error" });
          }}
        />
      </div>
    </div>
  );
};

export default Clients;
